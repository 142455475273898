import React from "react";

export const VisaIcon: React.FC = () => {
  return (
    <svg
      width="38"
      height="24"
      viewBox="0 0 60 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="59"
        height="37"
        rx="7.5"
        fill="white"
        stroke="#D3D3D3"
      />
      <path
        d="M24.5671 13.2298L19.1993 25.8033H15.6973L13.0558 15.7691C12.8954 15.151 12.756 14.9246 12.2682 14.6642C11.4719 14.2401 10.157 13.8422 9 13.5952L9.07858 13.2298H14.7158C15.4344 13.2298 16.0804 13.6994 16.2435 14.5118L17.6386 21.7875L21.0864 13.2296L24.5671 13.2298ZM38.2888 21.6982C38.3029 18.3796 33.6148 18.1968 33.6471 16.7144C33.6571 16.2632 34.0946 15.7835 35.0524 15.6611C35.5271 15.6001 36.835 15.5535 38.3185 16.2238L38.9004 13.5575C38.1032 13.2734 37.0776 13 35.8015 13C32.5268 13 30.2223 14.7091 30.2029 17.1563C30.1817 18.9664 31.8478 19.9765 33.103 20.578C34.3942 21.194 34.8275 21.5898 34.8226 22.1408C34.8134 22.9842 33.7927 23.3565 32.8388 23.3712C31.1736 23.3964 30.2074 22.9288 29.4371 22.5772L28.8366 25.3318C29.6107 25.6805 31.0395 25.9847 32.5209 26C36.0013 26 38.2781 24.3121 38.2888 21.6982ZM46.9359 25.8033H50L47.3254 13.2298H44.4973C43.8613 13.2298 43.3249 13.5933 43.0875 14.1522L38.116 25.8033H41.5948L42.2854 23.9253H46.5359L46.9359 25.8033ZM43.2393 21.3483L44.9831 16.6274L45.9867 21.3483H43.2393ZM29.3007 13.2298L26.5611 25.8033H23.2483L25.9889 13.2298H29.3007Z"
        fill="#1434CB"
      />
    </svg>
  );
};
