import axios from "axios";
const base_url = process.env.REACT_APP_DEVELOP_API;

const customerService = {
  async shippingCount(
    id: string,
    data: { id: string; orderCount: number | null; timeUpdate: string | null },
  ) {
    return axios.post(`${base_url}/customer/initialize/${id}`, data);
  },
  async checkCustomerExist(email: string) {
    return axios.get(`${base_url}/customer/verification/${email}`);
  },
};
export default customerService;
