import React from "react";
import { EmptyCartIcon } from "../../../Icon";

export const EmptyCart: React.FC = () => {
  return (
    <div className="flex flex-col min-w-full items-center h-auto min-h-screen gap-y-6 pt-20 p-20">
      <div className="flex w-full justify-center" id="confirmationSection">
        <EmptyCartIcon />
      </div>
      <div className="flex w-full flex-col justify-center items-center h-auto mt-5 p-4 border-solid border rounded">
        <span className="font-semibold font-lato text-lg">
          Votre panier semble être vide
        </span>
        <span className="font-normal font-lato text-sm text-[#707070]">
          Si cela est une erreur contacter notre support
        </span>
      </div>
      <button
        className="h-12 bg-black font-lato rounded w-1/2 px-5 text-white"
        onClick={() => (window.location.href = "https://www.codress.com")}
      >
        Continuez votre shopping
      </button>
    </div>
  );
};
