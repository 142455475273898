import React, { useEffect, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import { accessTokenType } from "../../types/accessToken.type";
import {
  CUSTOMER_ACCESS_TOKEN_CREATE,
  GET_CUSTOMER,
} from "../../../API/queries/Customer.query";
import { userStore } from "../../../stores/UserStore.atom";
import { ThemeType } from "../../../@types/Theme.type";
import { themeStore } from "../../../stores/ThemeStore.atom";
import classNames from "classnames";
import { EyeIcon, EyeSlashIcon } from "../../../Icon";
export const CheckoutLogin: React.FC<{
  // eslint-disable-next-line no-unused-vars
  handleBlur: (event: { target: { value: string } }) => void;
}> = ({ handleBlur }) => {
  const { setAuth } = useAuth();
  const setUser = useSetRecoilState<any>(userStore);
  const [user, setUserData] = useState<any>(undefined);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);

  const theme = useRecoilValue<ThemeType | undefined>(themeStore);

  const [customerAccessTokenCreate] = useMutation<accessTokenType>(
    CUSTOMER_ACCESS_TOKEN_CREATE,
    {
      context: {
        headers: {
          "X-Shopify-Storefront-Access-Token": process.env.REACT_APP_API_TOKEN,
        },
      },
    },
  );

  const [getUser] = useLazyQuery(GET_CUSTOMER, {
    context: {
      headers: {
        "X-Shopify-Storefront-Access-Token": process.env.REACT_APP_API_TOKEN,
      },
    },
  });

  useEffect(() => {
    const fetchData = async (token: string) => {
      try {
        await fetchCustomerData(token);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const storedAuth = localStorage.getItem("auth");
    if (storedAuth) {
      const tmp = JSON.parse(storedAuth);
      if (tmp.accessToken) {
        fetchData(tmp.accessToken);
      }
    }
  }, []);
  const handleLogout = async () => {
    try {
      setAuth({});
      localStorage.removeItem("auth");
      setUser(undefined);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setIsError(false);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setIsError(false);
  };

  const fetchCustomerData = async (accessToken: string) => {
    try {
      const userData = await getUser({
        variables: { customerAccessToken: accessToken },
      });
      setUser(userData.data.customer);
      setUserData(userData.data.customer);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleLogin = async () => {
    try {
      const response = await customerAccessTokenCreate({
        variables: {
          email: email,
          password: password,
        },
      });
      if (response?.data?.customerAccessTokenCreate.customerAccessToken) {
        const accessToken =
          response?.data?.customerAccessTokenCreate?.customerAccessToken
            ?.accessToken;
        setAuth({ accessToken });
        await fetchCustomerData(accessToken);
      } else {
        const errorMessage = "Unidentified customer";
        if (
          response?.data?.customerAccessTokenCreate?.customerUserErrors &&
          response?.data?.customerAccessTokenCreate?.customerUserErrors[0]
            .message === errorMessage
        ) {
          setIsError(true);
          console.error("Bad login");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className={classNames(
        `bg-[${theme?.brand.colors.primary[0].background}]`,
      )}
    >
      <div
        className={classNames(
          !user && "p-5 bg-white border-gray-200 border",
          "flex flex-col mt-[15px] w-full h-auto",
        )}
      >
        <span className=" mb-[10px] text-xl font-lato font-semibold">
          {!user ? "Identifiez vous" : "Contact"}
        </span>
        {isError && (
          <div className="flex justify-center mb-2 font-lato text-sm text-red-500">
            Email ou mot de passe invalide
          </div>
        )}
        {user ? (
          <div className="flex flex-col">
            <div className="flex flex-col w-full pointer-events-none opacity-50">
              <label className="mb-1 font-lato text-xs">Email</label>
              <div className="p-[15px] flex h-[49px] border border-solid border-gray-300 shadow-non font-lato text-sm">
                <span>{user.email}</span>
              </div>
            </div>
            <div className="w-auto">
              <label
                className="mb-1 mt-3 font-lato text-xs hover:cursor-pointer opacity-50 hover:underline-offset-4 hover:underline"
                onClick={handleLogout}
              >
                Se déconnecter ?
              </label>
            </div>
          </div>
        ) : (
          <div className="flex flex-col min-w-full justify-between gap-y-3 h-full">
            <div className="flex flex-col w-full ">
              <input
                type="email"
                onBlur={handleBlur}
                value={email}
                onChange={handleEmailChange}
                required
                placeholder="Email"
                className="p-[15px] flex h-[49px] w-full text-sm border border-solid border-gray-300 shadow-non font-lato"
              />
            </div>

            <div className="flex flex-col w-full verticalMobile:w-full">
              <div className="flex w-full relative">
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={handlePasswordChange}
                  required
                  placeholder="Mot de passe"
                  className="p-[15px] w-full flex h-[49px] border text-sm border-solid border-gray-300 shadow-non font-lato"
                />
                <div
                  className="absolute top-[50%] transform -translate-y-1/2 right-2 text-gray-400 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
                </div>
              </div>
            </div>
            <button
              className="bg-[#222122] flex justify-center items-center w-auto mobile:w-full h-[49px] px-5 py-[18px] rounded"
              onClick={handleLogin}
            >
              <span className="text-white font-normal font-lato">
                Connexion
              </span>
            </button>
            <div className="flex justify-center w-full mt-3 flex-col items-center gap-y-2">
              <span className="border-t border-gray-300 w-2/3"></span>
              <Link
                className="font-lato text-xs text-gray-400 w-auto uppercase"
                to="https://codress.com"
              >
                Continuer mon shopping
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
