import React, { ReactNode, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_SHOP } from "../API/queries/Customer.query";
import { useSetRecoilState } from "recoil";
import { themeStore } from "../stores/ThemeStore.atom";
import { ThemeType } from "../@types/Theme.type";

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const setShop = useSetRecoilState<ThemeType | undefined>(themeStore);

  const [getShop] = useLazyQuery(GET_SHOP, {
    context: {
      headers: {
        "X-Shopify-Storefront-Access-Token": process.env.REACT_APP_API_TOKEN,
      },
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const shopData = await getShop();
        setShop(shopData.data.shop);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return <div>{children}</div>;
};

export default ThemeProvider;
