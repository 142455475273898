import React from 'react';

interface LoadingSpinnerProps {
    message: string;
}
const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ message }) => {
  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-white bg-opacity-80 z-50">
        <div className="border-t-4 border-blue-600 border-dotted rounded-full w-20 h-20 animate-spin"></div>
        <span className="ml-5">{message}</span>
      </div>
    </>
  );
};

export default LoadingSpinner;
