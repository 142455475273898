import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { CART_REMOVE, GET_CART } from "../../API/queries/Cart.query";
import { toast } from "react-toastify";

import { CUSTOMER_CHECKOUT_CREATE } from "../../API/queries/Customer.query";
import { CheckoutLogin, CheckoutSummary, ConfirmCheckout } from "./components";
import { userStore } from "../../stores/UserStore.atom";
import { useRecoilValue } from "recoil";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { getCartElement } from "../utils/cart.utils";
import LoadingSpinner from "../../components/LoadingSpinner";
import checkoutService from "../../API/checkout/Checkout.service";

import { checkoutFormValuesType } from "./types/checkoutFormValues.type";
import { CartIcon, LockIcon } from "../../Icon";
import { Cart, CartLine, CartTransform } from "./types/Cart.type";
import classNames from "classnames";
import { validationSchemaLogin } from "./utils/validationSchema.utils";
import { User } from "../types/user.type";
import { ConfirmPaymentType } from "./types/ConfirmPayment.type";
import { UserCreate } from "./types/UserCreate.type";
import { ThemeType } from "../../@types/Theme.type";
import { themeStore } from "../../stores/ThemeStore.atom";
import { QuestionIcon } from "../../Icon/QuestionIcon";
import { EmptyCart } from "./components/EmptyCart";
import {
  AmexCartIcon,
  CreditCartIcon as CredidCartIcon,
  MasterCardIcon,
  VisaIcon,
} from "../../Icon/CreditCartIcon";
import { PaymentMethod } from "@stripe/stripe-js";
import orderService from "../../API/orders/Order.service";
import { CheckoutRegister } from "./components/ChekoutRegister";
import { Expedition } from "./components/Expedition";
import { SubscriptionTypeEnum } from "../types/subscriptionType.enum";

export default function CheckoutPage() {
  const [cartItemsIds, setCartItemsIds] = useState([]);
  const [isLoading, setIdLoading] = useState(false);
  const [expeditionType, setExpeditionType] = useState<string>(
    SubscriptionTypeEnum.CODRESS_PREMIUM,
  );
  const [isCartLoading, setIdCartLoading] = useState(true);
  const [subscriptionType, setSubscriptionType] = useState<string>("");
  const [cartItem, setCartItems] = useState<Cart>();
  const [isRegisterForm, setIsRegisterForm] = useState<boolean>(false);
  const [confirmPayment, setConfirmPayment] = useState<ConfirmPaymentType>();
  const [isPaymentConfirm, setIsPaymentConfirm] = useState<boolean>(false);
  const [couponId, setCouponId] = useState<string>();
  const [paymentPolicy, setPaymentPolicy] = useState<boolean>(false);

  const { search } = useLocation();
  const user = useRecoilValue<User | undefined>(userStore);
  const theme = useRecoilValue<ThemeType | undefined>(themeStore);
  const stripe = useStripe();
  const elements = useElements();
  const params = new URLSearchParams(search);
  const token = params.get("token");

  const [removeUserCart] = useMutation(CART_REMOVE, {
    context: {
      headers: {
        // eslint-disable-next-line no-undef
        "X-Shopify-Storefront-Access-Token": process.env.REACT_APP_API_TOKEN,
      },
    },
  });

  const [createCustomerCheckout] = useMutation<any>(CUSTOMER_CHECKOUT_CREATE, {
    context: {
      headers: {
        // eslint-disable-next-line no-undef
        "X-Shopify-Storefront-Access-Token": process.env.REACT_APP_API_TOKEN,
      },
    },
  });

  const countries = [
    { code: "FR", name: "France" },
    { code: "CA", name: "Canada" },
    { code: "GB", name: "United Kingdom" },
  ];

  const { data } = useQuery(GET_CART, {
    context: {
      headers: {
        // eslint-disable-next-line no-undef
        "X-Shopify-Storefront-Access-Token": process.env.REACT_APP_API_TOKEN,
      },
    },
    variables: { id: `gid://shopify/Cart/${token}` },
  });

  useEffect(() => {
    const tmp = data?.cart?.lines?.nodes?.map((line: CartLine) => line.id);
    setCartItemsIds(tmp);
    if (
      user &&
      user.metafields[0]?.key === "shippingCount" &&
      user.metafields[0].value
    ) {
      if (user.metafields[0].value === SubscriptionTypeEnum.CODRESS_PREMIUM) {
        setSubscriptionType(SubscriptionTypeEnum.CODRESS_PREMIUM);
      }
    }
    if (data?.cart) {
      setCartItems(data?.cart);
      setIdCartLoading(false);
    }
    if (user) {
      createCustomerCheckoutTracking(user.email).catch((e) => console.error(e));
    }
  }, [token, data, user, theme]);

  const createCustomerCheckoutTracking = async (value: string) => {
    const lineItems: { quantity: number; variantId: string }[] | undefined =
      cartItem?.lines.nodes.map((temp: CartLine) => {
        return {
          variantId: temp.merchandise.id,
          quantity: 1,
        };
      });
    await createCustomerCheckout({
      variables: { email: value, lineItems },
    });
  };

  const handleBlur = async (event: { target: { value: string } }) => {
    const { value } = event.target;
    createCustomerCheckoutTracking(value).catch((e) => console.error(e));
  };

  const defaultValue = useMemo(() => {
    return {
      firstName: user?.firstName ?? "",
      lastName: user?.lastName ?? "",
      phone: user?.phone ?? "+33",
      city: user?.defaultAddress?.city ?? "",
      country: user?.defaultAddress?.country ?? countries[0].name,
      postalCode: user?.defaultAddress?.provinceCode ?? "",
      address: user?.defaultAddress?.address1 ?? "",
      info: "",
    };
  }, [user]);

  const getCouponId = (couponId: string) => {
    setCouponId(couponId);
  };

  const setConfirmation = (
    orderData: string,
    payment: string,
    values: checkoutFormValuesType,
    paymentMethod: PaymentMethod,
  ) => {
    setConfirmPayment({
      orderName: orderData,
      subId: payment,
      form: values,
      paymentMethod: paymentMethod,
    });
    toast.success("Votre commande est en cours");
    removeUserCart({
      variables: {
        cartId: `gid://shopify/Cart/${token}`,
        lineIds: cartItemsIds,
      },
    });
    setIdLoading(false);
    setIsPaymentConfirm(true);
  };

  const enablePayment = () => {
    const cardNumberElement: any = elements?.getElement(CardNumberElement);
    const cardExpiryElement: any = elements?.getElement(CardExpiryElement);
    const cardCvcElement: any = elements?.getElement(CardCvcElement);

    const cardNumberComplete = cardNumberElement?._complete;
    const cardNumberValid =
      !cardNumberElement?._empty && cardNumberElement?._error === undefined;

    // Vérifiez si le champ de la date d'expiration est complet et valide
    const cardExpiryComplete = cardExpiryElement?._complete;
    const cardExpiryValid =
      !cardExpiryElement?._empty && cardExpiryElement?._error === undefined;

    // Vérifiez si le champ du CVC est complet et valide
    const cardCvcComplete = cardCvcElement?._complete;
    const cardCvcValid =
      !cardCvcElement?._empty && cardCvcElement?._error === undefined;

    // Vérifiez si tous les champs sont complets et valides
    if (
      cardNumberComplete &&
      cardNumberValid &&
      cardExpiryComplete &&
      cardExpiryValid &&
      cardCvcComplete &&
      cardCvcValid
    ) {
      return true;
    } else {
      toast.warning("Moyen de paiement incomplet ou incorrect");
      return false;
    }
  };
  const handleButtonClick = () => {
    setIsRegisterForm(!isRegisterForm);
  };
  const handleExpedition = (type: string) => {
    setExpeditionType(type);
  };

  const createSubscription = async (values: checkoutFormValuesType) => {
    setIdLoading(true);
    if (enablePayment()) {
      let userCreate: UserCreate | undefined;
      try {
        if (user) {
          const cardNumberElement = elements?.getElement(CardNumberElement);
          if (cardNumberElement && values) {
            const countryCode = countries.find(
              (country) => country.name === values.country,
            )?.code;
            const paymentMethod = await stripe?.createPaymentMethod({
              card: cardNumberElement,
              billing_details: {
                name: values.firstName,
                email: user?.email ?? values.email,
                address: {
                  line1: values.address,
                  city: values.city,
                  country: countryCode,
                },
              },
              type: "card",
            });
            if (paymentMethod?.paymentMethod) {
              const cart = getCartElement(data);
              if (subscriptionType !== SubscriptionTypeEnum.CODRESS_PREMIUM) {
                if (expeditionType === SubscriptionTypeEnum.STANDARD) {
                  cart.push({
                    amount: "12.00",
                    currencyCode: "EUR",
                    variantId: "shippingChargeOneTime",
                    productId: "shippingChargeOneTime",
                    productTitle: "shippingChargeOneTime",
                  });
                }
                if (expeditionType === SubscriptionTypeEnum.CODRESS_PREMIUM) {
                  cart.push({
                    amount: "4.90",
                    currencyCode: "EUR",
                    variantId: "monthTaxShipping",
                    productId: "monthTaxShipping",
                    productTitle: "monthTaxShipping",
                  });
                }
              }
              let userData = null;
              if (userCreate) {
                userData = {
                  email: userCreate.email,
                  firstName: userCreate.firstName,
                  id: userCreate.id,
                  lastName: userCreate.lastName,
                  phone: userCreate.phone,
                  metafield: null,
                  metafields: null,
                };
              }
              if (user) {
                userData = {
                  email: user.email,
                  firstName: user.firstName,
                  id: user.id,
                  lastName: user.lastName,
                  phone: user.phone,
                  metafield: user.metafield ?? null,
                  metafields:
                    user.metafields[0] === null ? null : user.metafields,
                };
              }

              let createCheckoutData: {
                form: checkoutFormValuesType;
                paymentMethodId: string;
                user: any;
                expeditionType: string;
                cart: CartTransform[];
                couponId?: string;
              } = {
                form: values,
                user: userData,
                expeditionType,
                paymentMethodId: paymentMethod.paymentMethod.id,
                cart,
              };
              if (couponId) {
                createCheckoutData = { ...createCheckoutData, couponId };
              }
              await checkoutService
                .createCheckout(createCheckoutData)
                .then(async (res) => {
                  if (res.data.paymentStatus === "succeeded") {
                    setConfirmation(
                      res?.data?.orderData,
                      res.data.payment,
                      values,
                      paymentMethod?.paymentMethod,
                    );
                  } else if (res.data.paymentStatus === "requires_action") {
                    await stripe
                      ?.confirmCardPayment(res.data.clientSecret, {
                        payment_method: paymentMethod.paymentMethod.id,
                      })
                      .then(async function (result) {
                        if (result.error) {
                          setIdLoading(false);

                          toast.error(
                            "Une erreur s'est produite, veuillez recommencer plus tard",
                          );
                          console.error(result.error.message);
                        } else {
                          if (result.paymentIntent.status === "succeeded") {
                            await orderService
                              .createOrder(createCheckoutData)
                              .then(async function (result) {
                                console.log(result);
                                setConfirmation(
                                  result?.data?.name,
                                  res.data.payment,
                                  values,
                                  paymentMethod?.paymentMethod,
                                );
                              });
                          }
                        }
                      });
                  } else {
                    setIdLoading(false);
                    if (res.data === "card_declined") {
                      toast.error("Votre carte a été refusé");
                    } else {
                      toast.error(
                        "Une erreur s'est produite, veuillez recommencer plus tard",
                      );
                    }
                  }
                });
            } else {
              setIdLoading(false);
              toast.error(paymentMethod?.error.message);
            }
          }
        } else {
          setIdLoading(false);
        }
      } catch (e: any) {
        setIdLoading(false);
        toast.warning(
          "Une erreur s'est produite, veuillez recommencer plus tard",
        );
        console.error(e);
      }
    } else {
      setIdLoading(false);
    }
  };

  return (
    <div
      className={classNames(`flex min-h-screen h-auto flex-col w-full`)}
      style={{
        backgroundColor: `${theme?.brand.colors.primary[0].background}`,
      }}
      id="chekout-page"
    >
      <div
        className={classNames(
          `w-full h-1/6 flex border-b verticalMobile:px-8 border-gray-300 py-3 mobile:px-10 lg:px-40 mobile:border-none slaptop:px-10`,
        )}
      >
        <button
          onClick={() => (window.location.href = "https://www.codress.com")}
        >
          <CartIcon />
        </button>
        <div className="w-full h-full flex justify-center items-center">
          <img
            src={theme?.brand?.logo?.image?.url}
            alt="logo.codress"
            className="w-40 cursor-pointer"
            onClick={() => (window.location.href = "https://www.codress.com")}
          />
        </div>
      </div>
      <div className="w-full h-full flex verticalMobile:flex-col slaptop:flex-col slaptop:min-h-screen justify-between">
        {isLoading && <LoadingSpinner message="Paiement en cours" />}
        {isCartLoading && token !== null && (
          <LoadingSpinner message="Recuperation du panier" />
        )}

        {token === null || (cartItem && cartItem?.lines.nodes.length === 0) ? (
          <EmptyCart />
        ) : (
          <div
            className="w-7/12 mobile:w-full min-h-screen mobile:min-h-full
          mobile:mb-3 h-auto flex justify-end px-[25px] mobile:px-0 verticalMobile:w-full mobile:flex-col slaptop:w-full slaptop:justify-center"
          >
            <div className="hidden mobile:block">
              {cartItem && cartItem.lines.nodes.length > 0 && (
                <CheckoutSummary
                  cart={cartItem}
                  expeditionType={expeditionType}
                  subscriptionType={subscriptionType}
                  user={user}
                  mobileDisplay={true}
                  handleCouponId={getCouponId}
                />
              )}
            </div>

            {isPaymentConfirm && confirmPayment ? (
              <ConfirmCheckout subscriptionData={confirmPayment} />
            ) : (
              <div className="slaptop:px-3 flex flex-col mobile:w-full w-[70%] slaptop:w-full verticalMobile:px-2 pr-5 h-auto">
                {!isRegisterForm && (
                  <div className={classNames(!user && "flex flex-col w-full")}>
                    {!user && (
                      <>
                        <div className="w-full mt-10 bg-white p-5 border-gray-200 border">
                          <label className="my-3 mb-[10px] text-xl font-lato font-semibold">
                            Pas encore de compte ?
                          </label>
                          <button
                            className="bg-[#222122] mt-3 flex justify-center items-center w-full h-[49px] px-5 py-[18px]rounded"
                            onClick={() => setIsRegisterForm(true)}
                          >
                            <span className="text-white font-lato">
                              Inscrivez vous
                            </span>
                          </button>
                        </div>
                      </>
                    )}
                    <CheckoutLogin handleBlur={handleBlur} />
                  </div>
                )}
                {user && !isRegisterForm && (
                  <Formik
                    enableReinitialize={true}
                    validationSchema={validationSchemaLogin}
                    initialValues={defaultValue}
                    onSubmit={createSubscription}
                  >
                    {({ errors, touched }) => (
                      <div className="flex flex-col w-full h-auto pt-2">
                        <div className="flex justify-between items-center">
                          <span className="my-3 mb-[10px] text-xl font-lato font-semibold">
                            Livraison
                          </span>
                        </div>
                        {errors.password && touched.password && (
                          <span className="text-red-500 text-xs text-center py-3">
                            Mots de passe minimum 6 caractères
                          </span>
                        )}
                        <Form className="gap-y-3 flex flex-col">
                          <div className="flex justify-between gap-x-2 verticalMobile:flex-col verticalMobile:gap-y-3">
                            <div className="flex flex-col w-full">
                              <Field
                                id="firstName"
                                name="firstName"
                                placeholder="Prénom"
                                type="text"
                                className={classNames(
                                  errors.firstName &&
                                    touched.firstName &&
                                    "border-red-500",
                                  "p-[15px] flex h-[49px] border border-solid text-sm border-gray-300 shadow-non font-lato",
                                )}
                              />
                            </div>

                            <div className="flex flex-col w-full">
                              <Field
                                id="lastName"
                                name="lastName"
                                type="text"
                                placeholder="Nom"
                                className={classNames(
                                  errors.lastName &&
                                    touched.lastName &&
                                    "border-red-500",
                                  "p-[15px] flex h-[49px] border border-solid text-sm border-gray-300 shadow-non font-lato",
                                )}
                              />
                            </div>
                          </div>
                          <div className="flex justify-between gap-x-2 verticalMobile:flex-col verticalMobile:gap-y-3">
                            <div className="flex flex-col w-[65%] mobile:w-full">
                              <Field
                                id="adresse"
                                name="address"
                                type="text"
                                placeholder="Adresse de livraison"
                                className={classNames(
                                  errors.address &&
                                    touched.address &&
                                    "border-red-500",
                                  "p-[15px] flex h-[49px] border text-sm border-solid border-gray-300 shadow-non font-lato",
                                )}
                              />
                            </div>
                            <div className="flex flex-col mobile:w-full w-[35%]">
                              <Field
                                id="postalCode"
                                name="postalCode"
                                type="text"
                                placeholder="Code postal"
                                className={classNames(
                                  errors.postalCode &&
                                    touched.postalCode &&
                                    "border-red-500",
                                  "p-[15px] flex h-[49px] border text-sm border-solid border-gray-300 shadow-non font-lato",
                                )}
                              />
                            </div>
                          </div>
                          <div className="flex flex-col w-full">
                            <Field
                              id="info"
                              name="info"
                              type="text"
                              placeholder="Appartement, suite, etc. (optionnel)"
                              className="p-[15px] flex h-[49px] border text-sm border-solid border-gray-300 shadow-non font-lato"
                            />
                          </div>
                          <div className="flex justify-between gap-x-2 verticalMobile:flex-col verticalMobile:gap-y-3">
                            <div className="flex flex-col w-full">
                              <Field
                                id="city"
                                name="city"
                                placeholder="Ville"
                                type="text"
                                className={classNames(
                                  errors.city &&
                                    touched.city &&
                                    "border-red-500",
                                  "p-[15px] flex h-[49px] border border-solid text-sm border-gray-300 shadow-non font-lato",
                                )}
                              />
                            </div>

                            <div className="flex flex-col w-full">
                              <Field
                                id="country"
                                name="country"
                                as="select"
                                placeholder="Pays"
                                multiple={false}
                                className="p-[15px] flex h-[49px] border text-sm border-solid border-gray-300 shadow-non font-lato bg-white"
                              >
                                {countries.map((country) => (
                                  <option
                                    key={country.code}
                                    value={country.name}
                                    className="bg-white"
                                  >
                                    {country.name}
                                  </option>
                                ))}
                              </Field>
                            </div>

                            <div className="flex flex-col w-full">
                              <Field
                                id="phone"
                                name="phone"
                                type="+33"
                                placeholder="Tel mobile"
                                className={classNames(
                                  errors.phone &&
                                    touched.phone &&
                                    "border-red-500",
                                  "p-[15px] flex h-[49px] border border-solid text-sm border-gray-300 shadow-non font-lato",
                                )}
                              />
                            </div>
                          </div>
                          <Expedition
                            handelExpeditionType={handleExpedition}
                            subscriptionType={subscriptionType}
                          />
                          <div className="flex flex-col">
                            <span className="text-xl font-lato font-semibold">
                              Paiement
                            </span>
                            <span className="text-xs font-lato opacity-50">
                              Toutes les transactions sont sécurisées et
                              chiffrées.
                            </span>
                          </div>

                          <div className=" w-full h-auto border space-y-3 bg-[#0000000b]">
                            <div className="w-full h-[49px] flex justify-between border-[#0e403a] border px-4 bg-[#F2F8F7] rounded-t">
                              <div className="inline-flex items-center space-x-2">
                                <label className="relative flex items-center rounded-full cursor-pointer">
                                  <input
                                    name="paiment"
                                    type="radio"
                                    defaultChecked
                                    className="peer relative h-4 w-4 cursor-pointer appearance-none rounded-full border border-[#222122] text-[#222122] transition-all checked:border-[#222122]"
                                    id="html"
                                  />
                                  <span className="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-2.5 w-2.5"
                                      viewBox="0 0 16 16"
                                      fill="currentColor"
                                    >
                                      <circle
                                        data-name="ellipse"
                                        cx="8"
                                        cy="8"
                                        r="8"
                                      ></circle>
                                    </svg>
                                  </span>
                                </label>
                                <span className="font-lato text-sm text-[#222122]">
                                  Carte de crédit
                                </span>
                              </div>
                              <div className="flex gap-x-2 items-center">
                                <VisaIcon />
                                <MasterCardIcon />
                                <CredidCartIcon />
                                <AmexCartIcon />
                              </div>
                            </div>
                            <div className="space-y-3 px-4 pb-4">
                              <div className="">
                                <div className="flex relative w-full">
                                  <CardNumberElement
                                    id="card-nr"
                                    className="text-sm text-gray-700 bg-white border rounded px-3 py-4 border-gray-300 h-[49px] w-full"
                                    options={{ showIcon: true }}
                                  />
                                  <div className=" absolute top-[50%] transform -translate-y-1/2  right-2 text-gray-400">
                                    <LockIcon />
                                  </div>
                                </div>
                              </div>
                              <div className="flex w-full gap-x-2">
                                <div className="w-1/2">
                                  <CardExpiryElement
                                    id="card-exp"
                                    className="text-sm text-gray-700 bg-white border rounded px-3 py-4 border-gray-300 h-[49px]"
                                  />
                                </div>
                                <div className="w-1/2">
                                  <div className="flex relative w-full">
                                    <CardCvcElement
                                      id="card-cvc"
                                      className="text-sm text-gray-700 bg-white border rounded px-3 py-4 border-gray-300 h-[49px] w-full"
                                    />
                                    <div className="absolute top-[50%] transform -translate-y-1/2 right-2 text-gray-400">
                                      <QuestionIcon />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="w-full gap-x-3 flex items-center">
                            <input
                              name="paymentPolicy"
                              type="checkbox"
                              onChange={() => setPaymentPolicy(!paymentPolicy)}
                              checked={paymentPolicy}
                            />
                            <span className="font-normal font-lato">
                              {`J'accepte les conditions `}
                              <Link
                                className="font-normal text-[#0e403a] font-lato underline-offset-4 underline"
                                to={theme?.termsOfService.url ?? ""}
                              >
                                {`générales d'utilisation du
                            service`}
                              </Link>
                            </span>
                          </div>
                          {isRegisterForm ? (
                            <button
                              type="submit"
                              className={classNames(
                                "h-12 rounded px-5 text-white font-lato bg-[#222122]",
                              )}
                            >
                              Créer votre compte et Payer
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className={classNames(
                                (!user || !paymentPolicy) &&
                                  "opacity-50 pointer-events-none",
                                "h-12 bg-[#222122] rounded px-5 text-white font-lato",
                              )}
                            >
                              Payer
                            </button>
                          )}
                        </Form>
                      </div>
                    )}
                  </Formik>
                )}
                {isRegisterForm && (
                  <CheckoutRegister handelRegister={handleButtonClick} />
                )}
                <div className="flex flex-wrap w-full gap-3 mt-5 justify-center text-xs">
                  <Link
                    className="font-normal text-[#0e403a] font-lato underline-offset-4 underline"
                    to={theme?.termsOfService.url ?? ""}
                  >
                    {`Conditions d'utilisation`}
                  </Link>
                  <Link
                    className="font-normal text-[#0e403a] font-lato underline-offset-4 underline"
                    to={theme?.privacyPolicy.url ?? ""}
                  >
                    {"Politique de confidentialité"}
                  </Link>
                  <Link
                    className="font-normal text-[#0e403a] font-lato underline-offset-4 underline"
                    to={theme?.shippingPolicy.url ?? ""}
                  >
                    {`Politique d'expédition`}
                  </Link>
                  <Link
                    className="font-normal text-[#0e403a] font-lato underline-offset-4 underline"
                    to={theme?.refundPolicy.url ?? ""}
                  >
                    {`Mentions légales`}
                  </Link>
                </div>
              </div>
            )}
          </div>
        )}
        {cartItem && cartItem.lines.nodes.length > 0 && (
          <CheckoutSummary
            expeditionType={expeditionType}
            cart={cartItem}
            subscriptionType={subscriptionType}
            user={user}
            mobileDisplay={false}
            handleCouponId={getCouponId}
          />
        )}
      </div>
    </div>
  );
}
