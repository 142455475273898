import React, { useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { ThemeType } from "../../../@types/Theme.type";
import { themeStore } from "../../../stores/ThemeStore.atom";
import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import { validationSchemaRegister } from "../utils/validationSchema.utils";
import { EyeIcon, EyeSlashIcon } from "../../../Icon";

import customerService from "../../../API/customer/Customer.service";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { customerCreationType } from "../types/customerCreation.type";
import {
  CUSTOMER_ACCESS_TOKEN_CREATE,
  CUSTOMER_CREATE,
} from "../../../API/queries/Customer.query";
import { checkoutRegisterFormValuesType } from "../types/checkoutRegisterFormValues.type";
import { accessTokenType } from "../../types/accessToken.type";
import useAuth from "../../../hooks/useAuth";
import LoadingSpinner from "../../../components/LoadingSpinner";

interface CheckoutRegisterProps {
  handelRegister: () => void;
}

export const CheckoutRegister: React.FC<CheckoutRegisterProps> = ({
  handelRegister,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIdLoading] = useState(false);

  const { setAuth } = useAuth();

  const theme = useRecoilValue<ThemeType | undefined>(themeStore);

  const [createCustomerQuery] = useMutation<customerCreationType>(
    CUSTOMER_CREATE,
    {
      context: {
        headers: {
          // eslint-disable-next-line no-undef
          "X-Shopify-Storefront-Access-Token": process.env.REACT_APP_API_TOKEN,
        },
      },
    },
  );

  const [customerAccessTokenCreate] = useMutation<accessTokenType>(
    CUSTOMER_ACCESS_TOKEN_CREATE,
    {
      context: {
        headers: {
          // eslint-disable-next-line no-undef
          "X-Shopify-Storefront-Access-Token": process.env.REACT_APP_API_TOKEN,
        },
      },
    },
  );

  const checkCustomer = async (values: checkoutRegisterFormValuesType) => {
    setIdLoading(true);
    try {
      if (values && values.email) {
        await customerService.checkCustomerExist(values.email);
      }
      return await createCustomerQuery({
        variables: {
          email: values.email,
          password: values.password,
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          acceptsMarketing: values.acceptsMarketing,
        },
      }).then(async (res) => {
        if (
          res.data?.customerCreate?.userErrors &&
          res.data?.customerCreate?.userErrors.length > 0
        ) {
          if (
            res.data?.customerCreate?.userErrors &&
            res.data?.customerCreate?.userErrors[0]
          ) {
            toast.error(res.data?.customerCreate?.userErrors[0].message);
            setIdLoading(false);
          }
        } else {
          toast.success("Votre compte a été créé");
          await handleLogin(values.email, values.password);
          setIdLoading(false);
        }
      });
    } catch (e: any) {
      toast.error(e.message);
      setIdLoading(false);

      return;
    }
  };

  const handleLogin = async (email: string, password: string) => {
    try {
      const response = await customerAccessTokenCreate({
        variables: {
          email: email,
          password: password,
        },
      });
      if (response?.data?.customerAccessTokenCreate.customerAccessToken) {
        const accessToken =
          response?.data?.customerAccessTokenCreate?.customerAccessToken
            ?.accessToken;
        setAuth({ accessToken });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        const errorMessage = "Unidentified customer";
        if (
          response?.data?.customerAccessTokenCreate?.customerUserErrors &&
          response?.data?.customerAccessTokenCreate?.customerUserErrors[0]
            .message === errorMessage
        ) {
          toast.error("Email ou mots de passe invalide");
          console.error("Bad login");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const defaultValue = useMemo(() => {
    return {
      firstName: "",
      passwordConfirm: "",
      lastName: "",
      email: "",
      password: "",
      phone: "+33",
      acceptsMarketing: true,
    };
  }, []);

  return (
    <div
      className={classNames(
        `bg-[${theme?.brand.colors.primary[0].background}]`,
      )}
    >
      {isLoading && (
        <LoadingSpinner message="Creation de votre compte en cours" />
      )}

      <Formik
        enableReinitialize={true}
        validationSchema={validationSchemaRegister}
        initialValues={defaultValue}
        onSubmit={checkCustomer}
      >
        {({ errors, touched }) => (
          <div className="flex flex-col w-full h-auto pt-2">
            <div className="flex justify-between items-center mt-10">
              <span className="my-3 mb-[10px] text-lg font-lato font-semibold">
                Inscription
              </span>
              <span
                className="my-3 mb-[10px] text-xs font-lato opacity-50 cursor-pointer"
                onClick={handelRegister}
              >
                Déjà client ?
              </span>
            </div>
            {errors.password && touched.password && (
              <span className="text-red-500 text-xs text-center py-3">
                Mots de passe minimum 6 caractères
              </span>
            )}
            <Form className="gap-y-3 flex flex-col">
              <div className="flex justify-between gap-x-2 verticalMobile:flex-col verticalMobile:gap-y-3">
                <div className="flex flex-col w-full">
                  <Field
                    id="firstName"
                    name="firstName"
                    placeholder="Prénom"
                    type="text"
                    className={classNames(
                      errors.firstName && touched.firstName && "border-red-500",
                      "p-[15px] flex h-[49px] border border-solid text-sm border-gray-300 shadow-non font-lato",
                    )}
                  />
                </div>

                <div className="flex flex-col w-full">
                  <Field
                    id="lastName"
                    name="lastName"
                    type="text"
                    placeholder="Nom"
                    className={classNames(
                      errors.lastName && touched.lastName && "border-red-500",
                      "p-[15px] flex h-[49px] border border-solid text-sm border-gray-300 shadow-non font-lato",
                    )}
                  />
                </div>
              </div>
              <div className="flex justify-between gap-x-2 verticalMobile:flex-col verticalMobile:gap-y-3">
                <div className="flex flex-col w-2/3 verticalMobile:w-full">
                  <Field
                    id="email"
                    name="email"
                    placeholder="Jane@example.com"
                    type="email"
                    className={classNames(
                      errors.email && touched.email && "border-red-500",
                      "p-[15px] flex h-[49px] border border-solid text-sm border-gray-300 shadow-non font-lato",
                    )}
                  />
                </div>
                <div className="flex flex-col w-1/3 verticalMobile:w-full">
                  <Field
                    id="phone"
                    name="phone"
                    type="+33"
                    placeholder="Tel mobile"
                    className={classNames(
                      errors.phone && touched.phone && "border-red-500",
                      "p-[15px] flex h-[49px] border border-solid text-sm border-gray-300 shadow-non font-lato",
                    )}
                  />
                </div>
              </div>
              <div className="flex justify-between gap-x-2 verticalMobile:flex-col verticalMobile:gap-y-3">
                <div className="flex flex-col w-full">
                  <div className="flex relative w-full">
                    <Field
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Mot de passe"
                      className={classNames(
                        errors.password && touched.password && "border-red-500",
                        "p-[15px] flex h-[49px] w-full border border-solid text-sm border-gray-300 shadow-non font-lato rounded",
                      )}
                    />
                    <div
                      className=" absolute top-[50%] transform -translate-y-1/2  right-2 text-gray-400 cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  <div className="flex relative w-full">
                    <Field
                      id="passwordConfirm"
                      name="passwordConfirm"
                      type="password"
                      placeholder="Confirmation mot de passe "
                      className={classNames(
                        errors.password && touched.password && "border-red-500",
                        "p-[15px] flex h-[49px] w-full border border-solid text-sm border-gray-300 shadow-non font-lato rounded",
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-x-2 justify-center">
                <Field
                  id="acceptsMarketing"
                  name="acceptsMarketing"
                  type="checkbox"
                  placeholder="Confirmation mot de passe"
                ></Field>
                <span className="font-lato text-xs opacity-50">
                  Recevez nos invitations et soyez au courant de tout avant tout
                  le monde.
                </span>
              </div>
              <button
                type="submit"
                className={classNames(
                  "h-12 bg-[#222122] rounded px-5 text-white font-lato",
                )}
              >
                Créer votre compte
              </button>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};
