import React, { useEffect, useState } from "react";
import { CheckedIcon } from "../../../Icon";
import classNames from "classnames";
import { SubscriptionTypeEnum } from "../../types/subscriptionType.enum";

interface ExpeditionProps {
  subscriptionType: string;
  // eslint-disable-next-line no-unused-vars
  handelExpeditionType: (type: string) => void;
}
export const Expedition: React.FC<ExpeditionProps> = ({
  handelExpeditionType,
  subscriptionType,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>(
    SubscriptionTypeEnum.CODRESS_PREMIUM,
  );

  useEffect(() => {
    if (subscriptionType === SubscriptionTypeEnum.CODRESS_PREMIUM) {
      setSelectedOption(SubscriptionTypeEnum.CODRESS_PREMIUM);
    } else if (subscriptionType === SubscriptionTypeEnum.STANDARD) {
      setSelectedOption(SubscriptionTypeEnum.STANDARD);
    } else {
      setSelectedOption(SubscriptionTypeEnum.CODRESS_PREMIUM);
    }
  }, [subscriptionType]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
    handelExpeditionType(event.target.value);
  };

  return (
    <>
      <div className="flex flex-col">
        <span className="text-xl font-lato font-semibold">
          {`Option de livraison`}
        </span>
      </div>
      <div
        className={classNames(
          subscriptionType === "codress+" && "opacity-50 pointer-events-none",
          "w-full h-auto border space-y-3 bg-[#0000000b]",
        )}
      >
        <div className="w-full min-h-[49px] h-auto flex justify-between border-[#0e403a] border px-4 bg-[#F2F8F7] flex-col pt-3 gap-y-2">
          <div className="inline-flex items-center space-x-2 w-full">
            <label className="relative flex items-center rounded-full cursor-pointer">
              <input
                name="expedition"
                value="standard"
                checked={selectedOption === SubscriptionTypeEnum.STANDARD}
                type="radio"
                onChange={handleOptionChange}
                className="peer relative h-4 w-4 cursor-pointer appearance-none rounded-full border border-[#222122] text-[#222122] transition-all checked:border-[#222122]"
                id="expedition"
              />
              <span className="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-2.5 w-2.5"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                >
                  <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
                </svg>
              </span>
            </label>
            <div className="flex flex-row justify-between w-full">
              <span className="font-lato text-sm text-[#222122]">
                Livraison standard à domicile
              </span>
              <span className="font-lato text-sm text-[#222122]">12 €</span>
            </div>
          </div>
          {selectedOption === SubscriptionTypeEnum.STANDARD && (
            <div className="flex flex-col w-full pb-2 pl-5 ">
              <div className="flex flex-row w-2/3 justify-between font-lato text-sm text-[#222122]">
                <span className="flex-row flex gap-x-2">
                  <CheckedIcon />
                  Frais de retour (1 colis retour)
                </span>
                <span>inclus</span>
              </div>
              <div className="flex flex-row w-2/3 justify-between font-lato text-sm text-[#222122] ">
                <span className="flex-row flex gap-x-2">
                  <CheckedIcon />
                  Frais de pressing
                </span>
                <span>inclus</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="w-full h-auto rounded-t space-y-3 bg-[#0000000b]">
        <div className="w-full min-h-[49px] h-auto justify-between border-[#5CDD9C] border bg-[#F2F8F7] flex-col pt-3 gap-y-2 rounded-t">
          <div className="px-4 inline-flex items-center space-x-2 w-full pb-4">
            <label className="relative flex items-center rounded-full cursor-pointer">
              <input
                name="expedition"
                type="radio"
                value="codress+"
                checked={
                  selectedOption === SubscriptionTypeEnum.CODRESS_PREMIUM
                }
                onChange={handleOptionChange}
                className="peer relative h-4 w-4 cursor-pointer appearance-none rounded-full border border-[#222122] text-[#222122] transition-all checked:border-[#222122]"
                id="expedition"
              />
              <span className="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-2.5 w-2.5"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                >
                  <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
                </svg>
              </span>
            </label>
            <div className="flex flex-row justify-between w-full">
              <span className="font-lato text-sm text-[#222122]">
                Livraison standard avec{" "}
                <span className="uppercase font-black text-[#5CDD9C]">
                  Codress+
                </span>
              </span>
              <span className="font-lato text-sm text-[#222122]">Gratuit</span>
            </div>
          </div>
          {selectedOption === SubscriptionTypeEnum.CODRESS_PREMIUM && (
            <div className="flex flex-col w-full bg-[#5CDD9C] pt-2">
              <div className="flex flex-col w-full font-lato text-sm px-10 text-white">
                <span className="flex-row flex gap-x-2">
                  <CheckedIcon />
                  Livraisons gratuites illimitées
                </span>
                <span className="flex-row flex gap-x-2">
                  <CheckedIcon />
                  Retours gratuites illimités
                </span>
                <span className="flex-row flex gap-x-2">
                  <CheckedIcon />
                  Pressing gratuit
                </span>
              </div>
              <div className="flex w-full items-end justify-end font-lato text-sm underline-offset-1 underline px-4 text-white pb-1">
                {subscriptionType !== SubscriptionTypeEnum.CODRESS_PREMIUM ? (
                  <span>Souscrivez pour 4.90€/mois</span>
                ) : (
                  <span>Abonnement en cours</span>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
