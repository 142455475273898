import React from "react";

export const MasterCardIcon: React.FC = () => {
  return (
    <svg
      width="38"
      height="24"
      viewBox="0 0 60 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="59"
        height="37"
        rx="7.5"
        fill="white"
        stroke="#D3D3D3"
      />
      <path
        d="M34.9509 10.2857H25.6104V27.0689H34.9509V10.2857Z"
        fill="#FF5F00"
      />
      <path
        d="M26.2035 18.6773C26.2035 15.4156 27.7158 12.3021 30.2659 10.2857C25.6401 6.63846 18.909 7.43907 15.2618 12.0945C11.6442 16.7203 12.4448 23.4217 17.1002 27.0689C20.9847 30.1231 26.4111 30.1231 30.2955 27.0689C27.7158 25.0526 26.2035 21.9391 26.2035 18.6773Z"
        fill="#EB001B"
      />
      <path
        d="M47.5532 18.6773C47.5532 24.5781 42.7792 29.3522 36.8783 29.3522C34.4765 29.3522 32.1636 28.5516 30.2955 27.0689C34.9213 23.4217 35.7219 16.7203 32.0747 12.0648C31.5409 11.4125 30.9479 10.7898 30.2955 10.2857C34.9213 6.63846 41.6524 7.43907 45.2699 12.0945C46.7526 13.9626 47.5532 16.2755 47.5532 18.6773Z"
        fill="#F79E1B"
      />
    </svg>
  );
};
