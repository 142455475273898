import { gql } from 'graphql-tag';

export const GET_CART = gql`
  query getCart($id: ID!) {
    cart(id: $id) {
      checkoutUrl
      id
      createdAt
      note
      totalQuantity
      updatedAt
      lines(first: 10) {
        nodes {
          cost {
            totalAmount {
              amount
              currencyCode
            }
          }
          id
          merchandise {
            ... on ProductVariant {
              id
              quantityAvailable
              sku
              title
              product {
                title
                id
                variants(first: 1) {
                  nodes {
                    id
                    title
                  }
                }
              }
              image {
                altText
                id
                src
                url
              }
            }
          }
          quantity
        }
      }
      cost {
        totalAmount {
          amount
          currencyCode
        }
      }
    }
  }
`;

export const CART_BUYER_IDENTITY = gql`
  mutation cartBuyerIdentityUpdate {
    cartBuyerIdentityUpdate(
      buyerIdentity: {
        countryCode: AF
        customerAccessToken: ""
        email: ""
        deliveryAddressPreferences: {
          deliveryAddress: { address1: "", lastName: "", firstName: "", country: "" }
        }
        walletPreferences: ""
        phone: ""
      }
      cartId: ""
    )
  }
`;

export const CART_REMOVE = gql`
  mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        checkoutUrl
        createdAt
        id
        note
        totalQuantity
        updatedAt
      }
    }
  }
`;
