import React from 'react';

export const CircleVerifyIcon: React.FC = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M49 25.001C49 38.2558 38.2548 49.001 25 49.001C11.7452 49.001 1 38.2558 1 25.001C1 11.7462 11.7452 1.00098 25 1.00098C38.2548 1.00098 49 11.7462 49 25.001Z"
        stroke="#1773B0"
        strokeWidth="2"
      />
      <path d="M15 24.5123L22.3067 31.819L35.1247 19.001" stroke="#1773B0" strokeWidth="2" />
    </svg>
  );
};
