import axios from "axios";
import { checkoutFormValuesType } from "../../pages/Checkout/types/checkoutFormValues.type";
import { CartTransform } from "../../pages/Checkout/types/Cart.type";
const base_url = process.env.REACT_APP_DEVELOP_API;

const orderService = {
  async createOrder(data: {
    form: checkoutFormValuesType;
    paymentMethodId: string;
    user: any;
    cart: CartTransform[];
    couponId?: string;
  }) {
    return axios.post(`${base_url}/orders/create`, data);
  },
};
export default orderService;
