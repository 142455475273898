import axios from "axios";
import { checkoutFormValuesType } from "../../pages/Checkout/types/checkoutFormValues.type";
import { CartTransform } from "../../pages/Checkout/types/Cart.type";
const base_url = process.env.REACT_APP_DEVELOP_API;

const checkoutService = {
  async createCheckout(data: {
    form: checkoutFormValuesType;
    paymentMethodId: string;
    user: any;
    cart: CartTransform[];
    couponId?: string;
  }) {
    return axios.post(`${base_url}/checkout/`, data);
  },

  async applyDiscountCode(discountCode: string, userStripeId: string | null) {
    return axios.get(`${base_url}/checkout/${discountCode}`, {
      params: { userStripeId },
    });
  },
};
export default checkoutService;
