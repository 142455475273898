import { CartLine, CartTransform } from '../Checkout/types/Cart.type';

export const getCartElement = (data: any): CartTransform[] => {
  return data.cart.lines.nodes.reduce((result: CartTransform[], cartLine: CartLine) => {
    const amount = cartLine.cost.totalAmount.amount;
    const currencyCode = cartLine.cost.totalAmount.currencyCode;

    const variantId = cartLine.merchandise.product.variants.nodes[0].id;
    const productTitle = cartLine.merchandise.product.title;
    const productId = cartLine.merchandise.product.id;

    result.push({
      amount,
      variantId,
      productTitle,
      currencyCode,
      productId
    });

    return result;
  }, []);
};
