import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import { RecoilRoot } from "recoil";

import "./App.css";

import { AuthProvider } from "./context/AuthProvider";

import CheckoutPage from "./pages/Checkout/CheckoutPage";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { ThemeProvider } from "./context/ThemeProvider";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
// eslint-disable-next-line no-undef
const stripePromise = loadStripe(
  process.env.REACT_APP_API_STRIPE_PU_KEY as string,
);

function App() {
  const router = createBrowserRouter([
    {
      path: "/error",
      element: <ErrorPage />,
    },

    {
      index: true,
      path: "/",
      element: <CheckoutPage />,
      errorElement: <ErrorPage />,
    },
  ]);

  return (
    <RecoilRoot>
      <div>
        <ThemeProvider>
          <AuthProvider>
            <ToastContainer />
            <Elements stripe={stripePromise}>
              <RouterProvider router={router} />
            </Elements>
          </AuthProvider>
        </ThemeProvider>
      </div>
    </RecoilRoot>
  );
}

export default App;
