import React from "react";

export const AmexCartIcon: React.FC = () => {
  return (
    <svg
      width="38"
      height="24"
      viewBox="0 0 60 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="59"
        height="37"
        rx="7.5"
        fill="white"
        stroke="#D3D3D3"
      />
      <path d="M43 32H17V6.00004H43V18.9987V32Z" fill="white" />
      <path
        d="M30.0484 15.4188H28.0732L29.0608 13.0385L30.0484 15.4188ZM31.3476 18.5061H33.5824L30.3706 11.2299H27.8135L24.6015 18.5061H26.7844L27.3873 17.0509H30.7342L31.3476 18.5061ZM41.0353 18.5061H43V11.2299H39.9439L38.3118 15.7618L36.6903 11.2299H33.5824V18.5061H35.5469V13.4127L37.418 18.5061H39.1642L41.0353 13.4022V18.5061ZM29.3733 25.1544V24.0212H33.479V22.3789H29.3733V21.246H33.583V19.562H27.3879V26.8382H33.583V25.1544H29.3733ZM41.0018 23.1824L43 25.3081V21.0744L41.0018 23.1824ZM40.3916 26.8382H43L39.5496 23.1794L43 19.562H40.4331L38.3024 21.8904L36.1921 19.562H33.5831L37.0133 23.2001L33.5831 26.8382H36.1194L38.2607 24.4891L40.3916 26.8382ZM43 32V27.8802H39.8633L38.2484 26.0945L36.6253 27.8802H26.2834V19.5577H22.9455L27.0858 10.1878H31.0788L32.5041 13.3978V10.1878H37.4466L38.3049 12.6068L39.1687 10.1878H43V6.00004H17V32H43Z"
        fill="#1977C6"
      />
    </svg>
  );
};
