import React, { useState, useMemo } from "react";
import { Cart } from "../types/Cart.type";
import checkoutService from "../../../API/checkout/Checkout.service";
import classNames from "classnames";
import { useRecoilValue } from "recoil";
import { ThemeType } from "../../../@types/Theme.type";
import { themeStore } from "../../../stores/ThemeStore.atom";
import { User } from "../../types/user.type";
import { ArrowDownIcon, ArrowUpIcon } from "../../../Icon";
import { SubscriptionTypeEnum } from "../../types/subscriptionType.enum";

interface CheckoutSummaryProps {
  cart: Cart;
  subscriptionType: string;
  user: User | undefined;
  expeditionType: string;
  mobileDisplay: boolean;
  // eslint-disable-next-line no-unused-vars
  handleCouponId: (couponId: string) => void;
}
export const CheckoutSummary: React.FC<CheckoutSummaryProps> = ({
  cart,
  subscriptionType,
  user,
  handleCouponId,
  expeditionType,
  mobileDisplay,
}) => {
  const [discountCode, setDiscountCode] = useState<string>("");
  const [isValid, setIsValid] = useState<string | undefined>(undefined);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [dropDown, setDropDown] = useState<boolean>(!mobileDisplay);

  const [couponValue, setCouponValue] = useState<{
    type: string;
    amount: number;
  } | null>();
  const [isShippingDiscount, setIsShippingDiscount] = useState<boolean>(false);
  const [isGlobalDiscount, setIsIsGlobalDiscount] = useState<boolean>(false);
  const theme = useRecoilValue<ThemeType | undefined>(themeStore);

  const getTotalCost = useMemo(() => {
    let globalCost = parseFloat(cart?.cost.totalAmount?.amount);

    if (!couponValue) {
      if (subscriptionType !== SubscriptionTypeEnum.CODRESS_PREMIUM) {
        if (expeditionType === SubscriptionTypeEnum.STANDARD) {
          return (globalCost + parseFloat("12.00")).toFixed(2);
        }
        if (expeditionType === SubscriptionTypeEnum.CODRESS_PREMIUM) {
          return (globalCost + parseFloat("4.90")).toFixed(2);
        }
      } else {
        globalCost.toFixed(2);
      }
    } else {
      if (isShippingDiscount) {
        return globalCost.toFixed(2);
      }

      if (couponValue.type === "amount") {
        return (globalCost - couponValue.amount / 100).toString();
      }

      const originalPrice = globalCost; // Example original price
      const discountAmount = (originalPrice * couponValue.amount) / 100;
      return (originalPrice - discountAmount).toString();
    }
    return globalCost.toString();
  }, [
    subscriptionType,
    user,
    isShippingDiscount,
    expeditionType,
    couponValue,
    isGlobalDiscount,
  ]);

  const getShippingCost = useMemo(() => {
    if (subscriptionType !== SubscriptionTypeEnum.CODRESS_PREMIUM) {
      if (expeditionType === SubscriptionTypeEnum.STANDARD) {
        return "12.00 €";
      }
      if (expeditionType === SubscriptionTypeEnum.CODRESS_PREMIUM) {
        return "4.90 €/mois";
      }
    } else {
      return "Gratuit";
    }
  }, [subscriptionType, user, expeditionType]);

  const getTva = useMemo(() => {
    const shippingCost = getShippingCost === "Gratuit" ? "0" : getShippingCost;
    const tmp1 =
      (parseFloat(getTotalCost) - parseFloat(shippingCost ?? "0")) / 1.2;
    const tmp = parseFloat(getTotalCost) - parseFloat(shippingCost ?? "0");

    return (tmp - tmp1).toFixed(2);
  }, [subscriptionType, user, expeditionType]);

  const submitDiscountCode = async () => {
    try {
      if (discountCode) {
        let userStripeId = null;
        if (
          user &&
          user.metafield?.key === "stripeCustomerId" &&
          user.metafield.value
        ) {
          userStripeId = user.metafield.value;
        }
        const discount = await checkoutService.applyDiscountCode(
          discountCode,
          userStripeId,
        );
        if (discount.data && discount.data.status === "Invalid") {
          setIsValid("Invalid");
          setMessage(discount.data.coupon);
        } else {
          setIsValid("Valid");
          setMessage("Code reduction appliqué");
          if (discount.data.coupon.metadata.productType === "monthlyShipping") {
            setIsShippingDiscount(true);
          } else if (
            discount.data.coupon.metadata.productType === "TOTALFREE"
          ) {
            setIsIsGlobalDiscount(true);
          }
          if (discount.data.coupon.amount_off) {
            setCouponValue({
              type: "amount",
              amount: discount.data.coupon.amount_off,
            });
          } else if (discount.data.coupon.percent_off) {
            setCouponValue({
              type: "percent",
              amount: discount.data.coupon.percent_off,
            });
          }
          handleCouponId(discount.data.coupon.id);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <div
      className={classNames(
        !mobileDisplay
          ? "verticalMobile:pt-5 verticalMobile:pb-3 slaptop:pb-2 "
          : "verticalMobile:py-2 slaptop:py-2",
        `w-5/12 verticalMobile:w-full mobile:w-full verticalMobile:px-3 slaptop:pr-4 pl-4 pt-12 h-auto border-gray-300 border-l mobile:border-none slaptop:w-full`,
      )}
      style={{
        backgroundColor: `${theme?.brand.colors.secondary[0].background}`,
      }}
    >
      {mobileDisplay && (
        <div
          className={classNames(
            dropDown && "mb-5",
            "h-auto w-full mobile:pr-3",
          )}
          onClick={() => setDropDown(!dropDown)}
        >
          <div className="flex justify-between font-lato hover:cursor-pointer">
            <div className="flex items-center justify-center gap-x-2">
              <span className="text-sm">
                {!dropDown
                  ? "Afficher le sommaire de la commande"
                  : "Fermer le sommaire de la commande"}
              </span>
              {!dropDown ? <ArrowDownIcon /> : <ArrowUpIcon />}
            </div>
            <span>{getTotalCost}€/mois</span>
          </div>
        </div>
      )}
      <div
        className={classNames(
          !dropDown && "hidden",
          "flex-col flex verticalMobile:px-0 slaptop:px-0 pl-8 h-full overflow-hidden",
        )}
      >
        <div className="gap-y-5 flex flex-col">
          {cart &&
            cart?.lines?.nodes?.map((item: any) => (
              <div
                key={item.id}
                className="flex items-center h-16 gap-x-4 hover:bg-white hover:outline hover:outline-1 hover:outline-gray-300 w-[371px] mobile:w-full hover:rounded slaptop:w-full"
              >
                <div className="w-16 h-16">
                  <div className="bg-[#707070] w-[21px] h-auto absolute rounded-full flex justify-center items-center ml-[52px] -mt-3">
                    <span className="text-white text-sm">1</span>
                  </div>
                  <img
                    src={item?.merchandise?.image.url}
                    alt="logo.codress"
                    className="w-16 h-full object-fill rounded"
                  />
                </div>
                <div className="flex-1 font-lato text-sm">
                  {item?.merchandise?.product?.title}
                </div>
                <div className="mr-3 font-lato text-sm">
                  {parseFloat(item.cost.totalAmount.amount).toFixed(2)} €/mois
                </div>
              </div>
            ))}
        </div>
        <div className="flex mt-5 w-[371px] mobile:w-full h-14 gap-x-3 slaptop:w-full">
          <input
            className="h-[49px] w-full p-[15px] border border-[#DEDEDE] text-sm bg-[#f5f5f5]"
            placeholder="Discount code"
            type="text"
            onChange={(e) => setDiscountCode(e.currentTarget.value)}
          />
          <button
            className="h-[49px] bg-gray-200 px-5 text-sm text-gray-500 border border-gray-300"
            onClick={submitDiscountCode}
          >
            Appliquer
          </button>
        </div>
        {isValid === "Valid" && (
          <span className="font-lato text-sm text-green-500">{message}</span>
        )}
        {isValid === "Invalid" && (
          <span className="font-lato text-sm text-red-500">{message}</span>
        )}
        <div className="flex flex-col mobile:w-full w-[371px] verticalMobile:h-full mobile:h-full h-16 mt-5 gap-y-1 slaptop:w-full slaptop:h-full">
          <div className="flex justify-between text-sm font-lato">
            {expeditionType === SubscriptionTypeEnum.CODRESS_PREMIUM ? (
              <span>
                Abonnement{" "}
                <span className="uppercase font-black text-[#5CDD9C]">
                  Codress+
                </span>
              </span>
            ) : (
              <span> Livraison standard à domicile</span>
            )}
            <span
              className={classNames(
                (isShippingDiscount || isGlobalDiscount) && "line-through",
              )}
            >
              {getShippingCost}
            </span>
          </div>
          <div className="flex justify-between font-semibold font-lato">
            <span className="text-lg">Total</span>
            <span>{getTotalCost}€/mois</span>
          </div>
          <div className="flex justify-between text-xs opacity-50 font-lato">
            <span>Taxes de {getTva} € incluse</span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
};
