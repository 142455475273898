import React from "react";
import { CircleVerifyIcon } from "../../../Icon";
import { checkoutFormValuesType } from "../types/checkoutFormValues.type";
import { PaymentMethod } from "@stripe/stripe-js";

interface ConfirmCheckoutProps {
  subscriptionData: {
    form: checkoutFormValuesType;
    subId: string;
    orderName: string;
    paymentMethod: PaymentMethod;
  };
}
export const ConfirmCheckout: React.FC<ConfirmCheckoutProps> = ({
  subscriptionData,
}) => {
  return (
    <div className="flex flex-col w-full h-auto gap-y-6 pt-20 pr-20 pl-10">
      <div
        className="flex w-full justify-center h-auto"
        id="confirmationSection"
      >
        <CircleVerifyIcon />
        <div className="flex flex-col w-full ml-3 font-lato">
          <span>Identifiant abonement {subscriptionData.subId} </span>
          <span>Merci {subscriptionData.form.firstName}</span>
        </div>
      </div>
      <div className="flex w-full flex-col justify-center h-auto mt-5 p-4 border-solid border rounded">
        <span className="font-semibold font-lato text-lg">
          Votre commande est confirmée
        </span>
        <span className="font-normal font-lato text-sm text-[#707070]">
          Vous allez recevoir un mail de confirmation avec votre numero de
          commande
        </span>
      </div>
      <div className="flex w-full flex-col justify-center h-auto p-4 border-solid border rounded">
        <span className="font-semibold font-lato text-base">
          Detail de votre commande
        </span>
        <div className="w-full flex gap-x-3 mt-4 font-lato">
          <div className="w-1/2 flex flex-col">
            <span className="text-sm text-[#707070]">Contact</span>
            <span>{subscriptionData.form.email}</span>
            <span className="text-sm text-[#707070]">
              Adresse de livraison{" "}
            </span>
            <span>
              {subscriptionData.form.lastName} {subscriptionData.form.firstName}
            </span>
            <span>{subscriptionData.form.address}</span>
            <span>{subscriptionData.form.country}</span>
          </div>
          <div className="w-1/2 flex-col flex font-lato">
            <span className="text-sm text-[#707070]">Methode de paiement</span>
            <span>
              {`**** **** **** ${subscriptionData.paymentMethod.card?.last4}`}
            </span>
            <span className="text-sm text-[#707070]">
              Adresse de facturation
            </span>
            <span>
              {subscriptionData.form.lastName} {subscriptionData.form.firstName}
            </span>
            <span>{subscriptionData.form.address}</span>
            <span>{subscriptionData.form.country}</span>
          </div>
        </div>
      </div>
      <button
        className="h-12 bg-black font-lato rounded px-5 text-white"
        onClick={() => (window.location.href = "https://www.codress.com")}
      >
        Continuez votre shopping
      </button>
    </div>
  );
};
