import React from "react";

export const ErrorPageIcon: React.FC = () => {
  return (
    <svg
      width="218"
      height="312"
      viewBox="0 0 118 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M79.4512 43.08C71.0812 43.08 62.5612 42.74 54.5912 40.52C46.7712 38.35 39.5912 34.14 33.1912 29.27C29.0012 26.1 25.1912 23.58 19.7512 23.96C14.4247 24.2516 9.3336 26.2502 5.23121 29.66C-1.68879 35.66 -0.648793 46.89 2.12121 54.78C6.28121 66.66 18.9412 74.89 29.7012 80.27C42.1312 86.48 55.7912 90.08 69.4812 92.16C81.4812 93.98 96.9012 95.31 107.301 87.47C116.851 80.27 119.471 63.83 117.131 52.73C116.564 49.4479 114.818 46.4858 112.221 44.4C105.511 39.5 95.5012 42.77 87.9612 42.94C85.1612 43 82.3112 43.07 79.4512 43.08Z"
        fill="#F2F2F2"
      />
      <path
        d="M58.9712 111.61C79.2456 111.61 95.6812 110.585 95.6812 109.32C95.6812 108.055 79.2456 107.03 58.9712 107.03C38.6969 107.03 22.2612 108.055 22.2612 109.32C22.2612 110.585 38.6969 111.61 58.9712 111.61Z"
        fill="#F2F2F2"
      />
      <path
        d="M96.4812 30.86V35.17"
        stroke="#BABABA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.3313 33.02H98.6313"
        stroke="#BABABA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.2612 67.08V71.38"
        stroke="#BABABA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.1013 69.23H26.4113"
        stroke="#BABABA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2312 12.94V17.24"
        stroke="#BABABA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0713 15.09H20.3813"
        stroke="#BABABA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.0913 2.96C65.6326 2.96 66.0713 2.52124 66.0713 1.98C66.0713 1.43876 65.6326 1 65.0913 1C64.5501 1 64.1113 1.43876 64.1113 1.98C64.1113 2.52124 64.5501 2.96 65.0913 2.96Z"
        fill="#CFCFCF"
      />
      <path
        d="M62.7512 98.56C63.2925 98.56 63.7312 98.1212 63.7312 97.58C63.7312 97.0387 63.2925 96.6 62.7512 96.6C62.21 96.6 61.7712 97.0387 61.7712 97.58C61.7712 98.1212 62.21 98.56 62.7512 98.56Z"
        fill="#CFCFCF"
      />
      <path
        d="M83.7967 7.63652L26.7636 13.5605C25.4617 13.6957 24.5159 14.8608 24.6511 16.1627L32.4513 91.2587C32.5865 92.5606 33.7515 93.5064 35.0534 93.3711L92.0866 87.4471C93.3885 87.3119 94.3343 86.1469 94.1991 84.845L86.3989 9.74898C86.2637 8.44707 85.0986 7.50129 83.7967 7.63652Z"
        fill="#D2D2D2"
      />
      <path
        d="M81.3426 11.1028L29.8695 16.4493C28.5676 16.5845 27.6218 17.7496 27.7571 19.0515L34.8113 86.9661C34.9465 88.268 36.1116 89.2138 37.4135 89.0786L88.8865 83.7321C90.1885 83.5969 91.1342 82.4318 90.999 81.1299L83.9448 13.2153C83.8095 11.9134 82.6445 10.9676 81.3426 11.1028Z"
        fill="white"
        stroke="#BABABA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.4613 14.43L71.6613 6.72998C71.6212 6.41038 71.4573 6.11927 71.2049 5.91919C70.9525 5.71912 70.6316 5.62605 70.3113 5.65998L60.7913 6.65998C60.7138 5.89174 60.4858 5.1463 60.1202 4.46619C59.7546 3.78609 59.2587 3.18465 58.6607 2.69621C58.0627 2.20777 57.3743 1.84191 56.6349 1.6195C55.8955 1.39709 55.1195 1.3225 54.3513 1.39998C53.5831 1.47746 52.8376 1.70549 52.1575 2.07106C51.4774 2.43664 50.876 2.93259 50.3875 3.5306C49.4011 4.73833 48.9348 6.28846 49.0913 7.83998L39.5413 8.83998C39.3804 8.85622 39.2242 8.90405 39.0818 8.98072C38.9394 9.05739 38.8134 9.1614 38.7113 9.2868C38.6091 9.4122 38.5327 9.55652 38.4863 9.7115C38.44 9.86648 38.4247 10.0291 38.4413 10.19L39.2413 17.89C39.2575 18.0509 39.3054 18.2071 39.382 18.3495C39.4587 18.4919 39.5627 18.6178 39.6881 18.72C39.8135 18.8222 39.9579 18.8986 40.1128 18.945C40.2678 18.9913 40.4304 19.0066 40.5913 18.99L71.3713 15.79C71.5328 15.7739 71.6895 15.7257 71.8321 15.6482C71.9748 15.5708 72.1005 15.4657 72.2021 15.339C72.3036 15.2123 72.3788 15.0667 72.4233 14.9106C72.4678 14.7545 72.4807 14.5911 72.4613 14.43Z"
        fill="white"
        stroke="#BABABA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.7112 61.37C48.0387 58.4026 49.3723 55.6367 51.49 53.5324C53.6078 51.4282 56.3822 50.1123 59.3516 49.8038C62.3211 49.4953 65.3067 50.2127 67.8118 51.8367C70.317 53.4606 72.1906 55.8933 73.1212 58.73"
        stroke="#BABABA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.1713 38.47L44.3813 44.37"
        stroke="#BABABA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.8313 39.03L49.7213 43.81"
        stroke="#BABABA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.3313 36.0699L67.5413 41.9599"
        stroke="#BABABA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.9912 36.62L72.8812 41.41"
        stroke="#BABABA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
