import * as Yup from "yup";

export const validationSchemaLogin = Yup.object().shape({
  firstName: Yup.string().required("Le prénom est requis"),
  lastName: Yup.string().required("Le nom de famille est requis"),
  phone: Yup.string()
    .matches(/^\+33\d{9}$/, "Numéro de téléphone invalide")
    .required("Numéro de téléphone est requis"),
  city: Yup.string().required("La ville est requise"),
  country: Yup.string().required("Le pays est requis"),
  postalCode: Yup.string()
    .matches(/^[0-9]{5}$/, "Le code postal doit contenir 5 chiffres")
    .required("Le code postal est requis"),
  address: Yup.string().required("L'adresse est requise"),
  info: Yup.string().optional(),
});

export const validationSchemaRegister = Yup.object().shape({
  firstName: Yup.string().required("Le prénom est requis"),
  lastName: Yup.string().required("Le nom de famille est requis"),
  email: Yup.string().email("Adresse e-mail invalide").required(),
  password: Yup.string().min(6).required(),
  passwordConfirm: Yup.string().min(6).required(),
  phone: Yup.string()
    .matches(/^\+33\d{9}$/, "Numéro de téléphone invalide")
    .required("Numéro de téléphone est requis"),
});
